.my-tasks {
  overflow: hidden;

  .middle-content {
    padding: 0 !important;
  }

  .tasks-main-wrp {
    display: flex;
    align-items: flex-start;
    overflow: auto;

    .tasks-column {
      flex: 1 1 20%;
      min-width: 330px;
      padding: 0 19px;
      padding-right: 10px;
      padding-top: 26px;
      height: 100%;
      border-right: 1px solid $grey-200;
      position: relative;
      z-index: 1;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 6px;
        background: $grey-200;
      }

      &.overdue {
        background-color: $red-100;

        .tasks-title-wrp {
          color: $red;

          .task-count {
            background-color: $red;
            color: $white;
          }
        }

        &::before {
          background: $red-200;
        }
      }

      &.in-progress {
        &::before {
          background: #8fdff2;
        }
      }

      &.on-hold {
        &::before {
          background: $red-100;
        }
      }

      &.qa-ready {
        &::before {
          background: #f4f7a9;
        }
      }

      &.completed {
        &::before {
          background: #8ff2c0;
        }
      }

      .tasks-title-wrp {
        font-size: 20px;
        font-weight: 800;
        padding-right: 16px;
        position: relative;
        z-index: 1;
        line-height: normal;
        margin-bottom: 16px;
        display: inline-block;

        .task-count {
          position: absolute;
          top: 6px;
          left: 100%;
          z-index: 1;
          display: inline-block;
          font-size: 12px;
          background: $grey-300;
          color: $grey-500;
          border-radius: 30px;
          padding: 0 5px;
          line-height: normal;
        }
      }

      ::-webkit-scrollbar {
        width: 5px;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 5px;
      }

      .tasks-wrp {
        overflow: auto;
        overflow-x: hidden;
        height: calc(100vh - 175px);
        scrollbar-color: rgba(117, 117, 117, 0.5) transparent;

        &::-webkit-scrollbar {
          width: 0;
        }

        .tasks-inner-wrp {
          padding-right: 10px;

          .card {
            padding: 18px;
            padding-bottom: 15px;
            z-index: 1;
            margin: 20px 0;
            max-width: none;

            &.flag-red {
              &::before {
                background-color: #ff7b7b;
              }
            }

            &.flag-yellow {
              &::before {
                background-color: $primary;
              }
            }

            &.flag-blue {
              &::before {
                background-color: $blue-light;
              }
            }

            &.flag-default {
              &::before {
                background-color: $grey-400;
              }
            }

            &.overdue {
              border: 2px solid $red-200;
            }

            &::before {
              content: "";
              position: absolute;
              top: 21px;
              left: 0;
              z-index: -1;
              width: 23px;
              height: 20px;
              // background-color: $primary;
              background-image: url($flag);
              background-position: 22% center;
              background-repeat: no-repeat;
              background-size: 50% 45%;
              border-radius: 0px 25px 25px 0px;
            }

            .card-description {
              .logo-title-wrp {
                display: flex;
                align-items: center;
                margin-bottom: 6px;
                gap: 0.5rem !important;

                .logo-wrp {
                  max-width: 26px;
                  width: 100%;
                  height: 26px;
                  border-radius: 50%;
                  overflow: hidden;

                  img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                  }
                }

                .title-wrp {
                  font-size: 14px;
                  font-weight: 700;
                }
              }

              .description-wrp {
                .description {
                  display: none;
                  font-size: 12px;
                  line-height: 18px;
                  font-weight: 600;
                  color: $grey-500;
                  margin-bottom: 8px;
                }
              }
            }

            .date-time-wrp {
              padding-top: 10px;
              margin-top: 24px;
              border-top: 1px solid $card-border-color;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .date-wrp {
                font-size: 12px;
                font-weight: 600;
              }

              .time-wrp {
                display: flex;
                align-items: center;

                span {
                  font-size: 10px;
                  font-weight: 700;

                  &.estimated {
                    background-color: $grey-200;
                    border-radius: 5px;
                    padding: 5px 7px;
                    line-height: normal;
                    display: inline-block;
                    margin-left: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
