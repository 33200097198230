.create-parent-task {
  .form-inner-wrp {
    height: 100vh;

    .form-content-wrp {
      height: 100%;
      overflow: auto;
      padding-bottom: 100px;

      .task-header {
        padding: 20px 48px;
        border-bottom: 1px solid $body-border-color;
        position: sticky;
        top: 0;
        z-index: 6;
        background: $white;

        @include media-breakpoint-up(xxl) {
          padding: 28px 48px;
        }

        h2 {
          font-size: 32px;
          font-weight: 800;
        }
      }

      .task-content {
        padding: 0 48px;
        margin-top: 20px;

        .form-label {
          margin-bottom: 5px;

          @include media-breakpoint-up(xxl) {
            margin-bottom: 15px;
          }
        }

        #task_description {
          min-height: 200px;

          @include media-breakpoint-up(xxl) {
            min-height: 420px;
          }
        }

        .upload-wrp {
          width: 100%;
          height: 170px;

          input[type="file"] {
            height: 100%;
            width: 100%;
            background: $grey-100;
            border: 1px solid $grey-300;
            border-radius: 5px;

            &::-webkit-file-upload-button {
              display: none;
            }

            &::file-selector-button {
              display: none;
            }
          }
        }

        .file-upload-wrp {
          .upload-file-wrp {
            margin: 0 -5px;

            .upload-file-item {
              background-color: $card-border-color;
              padding: 5px 7px;
              border-radius: 5px;
              color: $secondary;
              font-size: 12px;
              font-weight: 600;
              display: inline-block;
              line-height: normal;
              margin: 5px;
              text-decoration: none;
            }
          }
        }
      }
    }

    .button-wrp {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 1;
      padding: 24px 50px;
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid $body-border-color;
      background-color: #f7f7f7;
    }
  }
}
