.card-progressbar {
  padding: 30px 18px;
  border-top: 1px solid $card-border-color;

  .label-wrp {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  .progress-wrp {
    box-shadow: 0px 4px 10px #0000001A;

    > div {
      height: 8px !important;
      background-color: #D8F5CF !important;
      border: 2px solid $white;

      > div {
        height: 4px !important;
        background-color: #28CB13 !important;
      }
    }

    span {
      display: none !important;
    }
  }
}
