.left-sidebar {
  .logo-wrp {
    padding: 0 22px;
    min-height: 100px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $body-border-color;

    .navbar-brand {
      display: inline-block;
      max-width: 50px;

      img {
        width: 100%;
      }
    }
  }

  .left-sidebar-inner-wrp {
    padding: 0 22px;
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .main-navigation {
      margin-top: 40px;

      .accordion {
        .accordion-item {
          border: none;
          background: none;
          margin-bottom: 30px;

          .accordion-header {
            .accordion-button {
              padding: 0;
              border: none;
              box-shadow: none;
              background-color: transparent;
              font-weight: 700;
              color: $grey-500;
              padding-left: 40px;
              position: relative;
              z-index: 1;
              line-height: normal;
              font-size: 16px;

              &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                width: 20px;
                height: 20px;
                display: block;
                background-position: center center;
                background-size: contain;
                background-repeat: no-repeat;
              }

              &::after {
                transform: rotate(270deg);
              }

              &:not(.collapsed) {
                color: $secondary;

                &::after {
                  transform: rotate(360deg);
                }
              }
            }
          }

          .accordion-collapse {
            .accordion-body {
              padding: 20px;
              padding-left: 40px;

              .menu-item-wrp {
                line-height: normal;
                margin-bottom: 20px;

                &:last-child {
                  margin-bottom: 0;
                }

                .menu-item {
                  color: $grey-500;
                  font-weight: 500;
                  text-decoration: none;
                  font-size: 16px;
                  line-height: normal;
                  display: inline-block;

                  @include custom-transition(color);

                  &:hover,
                  &.active {
                    color: $secondary;
                  }
                }
              }
            }
          }

          &.client {
            .accordion-header {
              .accordion-button {
                &::before {
                  background-image: url($checklist-hover);
                }

                &:not(.collapsed) {
                  color: $secondary;

                  &::before {
                    background-image: url($checklist);
                  }
                }
              }
            }
          }

          &.project {
            .accordion-header {
              .accordion-button {
                &::before {
                  background-image: url($medal-hover);

                  @include custom-transition(background-image);
                }

                &:not(.collapsed) {
                  color: $secondary;

                  &::before {
                    background-image: url($medal);
                  }
                }
              }
            }
          }

          &.tasks {
            .accordion-header {
              .accordion-button {
                &::before {
                  background-image: url($checklist-hover);
                }

                &:not(.collapsed) {
                  color: $secondary;

                  &::before {
                    background-image: url($checklist);
                  }
                }
              }
            }
          }

          &.time {
            .accordion-header {
              .accordion-button {
                &::before {
                  background-image: url($clock-hover);
                }

                &:not(.collapsed) {
                  color: $secondary;

                  &::before {
                    background-image: url($clock);
                  }
                }
              }
            }
          }

          &.dashboard {
            .accordion-header {
              .accordion-button {
                &::before {
                  background-image: url($dashboard-hover);
                }

                &:not(.collapsed) {
                  color: $secondary;

                  &::before {
                    background-image: url($dashboard);
                  }
                }
              }
            }
          }

          &.team {
            .accordion-header {
              .accordion-button {
                &::before {
                  background-image: url($team-hover);
                }

                &:not(.collapsed) {
                  color: $secondary;

                  &::before {
                    background-image: url($team);
                  }
                }
              }
            }
          }

          &.leaves {
            .accordion-header {
              .accordion-button {
                &::before {
                  background-image: url($leaves-hover);
                }

                &:not(.collapsed) {
                  color: $secondary;

                  &::before {
                    background-image: url($leaves);
                  }
                }
              }
            }
          }
        }
      }
    }

    // single menuitem styles
    .single-menu-item-wrp {
      .single-menu-item {
        margin-bottom: 30px;

        a {
          color: #9e9e9e;
          padding-left: 40px;
          position: relative;
          z-index: 1;
          line-height: normal;
          text-decoration: none;
          font-weight: 700;
          font-size: 16px;

          @include custom-transition(color);

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 20px;
            height: 20px;
            display: block;
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;

            @include custom-transition(background-image);
          }

          &:hover {
            color: $secondary;
          }
        }

        &.report {
          a {
            &::before {
              background-image: url($leaves-hover);
            }

            &:hover {
              &::before {
                background-image: url($leaves);
              }
            }
          }
        }

        &.users {
          a {
            &::before {
              background-image: url($team-hover);
            }

            &:hover {
              &::before {
                background-image: url($team);
              }
            }
          }
        }

        &.bees-settings {
          a {
            &::before {
              background-image: url($settings-hover);
            }

            &:hover {
              &::before {
                background-image: url($settings);
              }
            }
          }
        }

        &.logout {
          a {
            &::before {
              background-image: url($logout-hover);
            }

            &:hover {
              &::before {
                background-image: url($logout);
              }
            }
          }
        }
      }
    }

    // logout wrp styles
    .logout-wrp {
      padding-top: 25px;
      position: relative;
      z-index: 1;

      .logout-item {
        margin: 0 -22px;
        background-color: #f7f4ed;
        min-height: 70px;
        padding: 20px 22px;
        display: flex;
        align-items: center;

        .image-wrp {
          margin-right: 15px;
        }

        .user-name {
          font-size: 16px;
          font-weight: 700;
          color: $secondary;
        }
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        display: block;
        height: 1px;
        width: calc(100% + 44px);
        margin-left: -22px;
        background-color: $body-border-color;
      }
    }
  }
}
