.task-content-wrp {
  display: flex;
  align-items: stretch;

  .task-name-wrp {
    flex: 1 0 38%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;

    .task-name {
      padding: 20px 0;
      display: flex;
      align-items: center;
      font-weight: 600;
      color: $secondary;
      padding-left: 50px;
    }

    .btn-wrp {
      opacity: 0;

      @include custom-transition(opacity);
    }
  }

  .task-detail-wrp {
    flex: 1 0 62%;
    display: flex;

    .assignee,
    .due-date,
    .status-wrp,
    .estimate,
    .spent,
    .priority {
      flex: 1 0 10%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px 10px;
      border-right: 1px solid $card-border-color;
      font-weight: 600;
      color: $secondary;
    }

    .assignee {
      border-left: 1px solid $card-border-color;
      justify-content: flex-start;
      padding-left: 20px;

      .image-wrp {
        display: flex;
        padding-left: 6px;

        .image-item {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          overflow: hidden;
          border: 2px solid $white;
          margin-left: -6px;
        }
      }
    }

    .priority {
      border: none;

      img {
        width: 18px;
      }
    }

    .due-date {
      flex: 1 0 12%;
    }
  }
}
