/** Import Bootstrap functions */
@import "~bootstrap/scss/functions";

// theme colors
$primary: #ffdc47;
$primary-dark: #f7d649;
$secondary: #05253c;
$secondary-dark: #011421;

$orange: #ff8002;
$orange-light: #ffe5c7;

$gold: #FFD700;
$silver: #C0C0C0;

$white: #fff;
$grey-50: #fafafa;
$grey-100: #f5f5f5;
$grey-200: #eeeeee;
$grey-300: #e0e0e0;
$grey-400: #bdbdbd;
$grey-500: #9e9e9e;
$grey-600: #757575;
$grey-700: #616161;
$grey-800: #424242;
$grey-900: #212121;
$black: #000;

// additional colors
// blue variations
$blue-100: #d2f5fd;
$blue-200: #94c6d8;
$blue-300: #397b9d;
$blue: #115ae3;
$blue-light: #47c6ff;

// yellow variations
$yellow-100: #ffffde;
$yellow-200: #f8f4c2;
$yellow-300: #e8e89b;
$yellow-400: #a39f64;
$yellow: #838320;

// red variations
$red-100: #ffe0e0;
$red-200: #d89494;
$red-300: #cc6262;
$red: #ac3939;
$red-light: #e02121;

// green variations
$green-100: #b4f2c2;
$green-200: #94d8a3;
$green: #118329;
$green-light: #3ed823;

// toggle btn variation
$toggle-active: #1de549;

// body border lines color
$body-border-color: #dadcde;

// project card colors
$card-border-color: #ececec;
$card-footer-bg: #f8f8f8;

// bg colors
$body-bg-color: #fcfaf6;

// general font
$manrope-font: "Manrope", sans-serif;

// font weights
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-extrabold: 800;

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1400px,
  xxl: 1700px,
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-gutter-width: 52px !default;

// common variables
$height-full: 100%;

// images
$settings: "../../icons/settings.svg";
$settings-hover: "../../icons/settings-hover.svg";
$logout: "../../icons/logout.svg";
$logout-hover: "../../icons/logout-hover.svg";
$check-icon: "../../icons/check-solid.svg";
$form-check-icon: "../../icons/square-check.svg";
$medal: "../../icons/medal.svg";
$medal-hover: "../../icons/medal-hover.svg";
$clock: "../../icons/clock.svg";
$clock-hover: "../../icons/clock-hover.svg";
$checklist: "../../icons/checklist.svg";
$checklist-hover: "../../icons/checklist-hover.svg";
$dashboard: "../../icons/dashboard.svg";
$dashboard-hover: "../../icons/dashboard-hover.svg";
$team: "../../icons/team.svg";
$team-hover: "../../icons/team-hover.svg";
$leaves: "../../icons/leaves.svg";
$leaves-hover: "../../icons/leaves-hover.svg";
$menu-arrow: "../../icons/menu-arrow.svg";
$menu-arrow-active: "../../icons/menu-arrow-active.svg";
$tick: "../../icons/tick.svg";
$timer: "../../icons/timer.svg";
$trash: "../../icons/trash.svg";
$trash-disabled: "../../icons/trash-disabled.svg";
$calendar: "../../icons/calendar.svg";
$edit: "../../icons/edit.svg";
$logo-upload: "../../icons/logo-upload.svg";
$page-loader: "../../icons/loader.svg";
$modal-trash: "../../icons/modal-trash.svg";
$flag: "../../icons/flag.svg";
$flag-red: "../../icons/flag-red.svg";
$flag-yellow: "../../icons/flag-yellow.svg";
$flag-green: "../../icons/flag-green.svg";
$flag-blue: "../../icons/flag-blue.svg";
$flag-white: "../../icons/flag-white.svg";
$clock-hour: "../../icons/clock-hours.svg";
$angle-down: "../../icons/angle-down.svg";
$arrow-up-new-tab: "../../../icons/arrow-up-new-tab.svg";
