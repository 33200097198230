.all-clients-wrp {
  .card-wrp {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: 10px;

    .card-item-wrp {
      width: 50%;
      padding: 10px;

      @include media-breakpoint-up (lg) {
        width: 33.33%;
      }

      @include media-breakpoint-up (xl) {
        width: 25%;
      }

      .card {
        height: 100%;
        width: 100%;
        min-width: auto;
        max-width: none;
      }
    }
  }
}
