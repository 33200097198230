h1 {
  font-size: 32px;
  line-height: 44px;
  margin: 0;
}

h2 {
  font-size: 24px;
  line-height: 32px;
}

h5 {
  font-size: 16px;
  line-height: 22px;
  color: $grey-500;
  margin-bottom: 20px;
}

h1,
h2,
h5 {
  font-weight: $font-extrabold;
}

a {
  color: $blue;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

p {
  &:last-child {
    margin: 0;
  }
}

.large-para {
  font-size: 20px;
  line-height: normal;
  font-weight: 700;

  &.ex-bold {
    font-weight: 800;
  }
}