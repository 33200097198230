.right-sidebar-content {
  height: calc(100vh - 130px);

  .tabbed-btn-wrp {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;

    .tabbed-btn {
      border-bottom: 1px solid #DFE0E2;

      &.active {
        border-bottom: none;
        background-color: $white;
      }
    }
  }

  .content-box {
    margin-bottom: 30px;

    &:first-of-type {
      margin-top: 30px;
    }

    &.project-wrp,
    &.stats-wrp {
      margin-top: 0;

      .card {
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        .card-stats {
          .stat-item {
            border-top: 1px solid $card-border-color;
          }
        }
      }
    }
  }
}
