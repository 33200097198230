.avatar-outer-wrp {
  display: inline-block;
  position: relative;
  z-index: 1;

  .avatar-wrp {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;

    &.avatar-small {
      width: 30px;
      height: 30px;
    }

    &.avatar-large {
      width: 54px;
      height: 54px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .close-icon {
    border: none;
    box-shadow: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $red-100;
    border: 1px solid $red-200;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;

    @include custom-transition(opacity);

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 11px;
      height: 1px;
      background: $red-300;
      top: 50%;
      left: 50%;
      z-index: 1;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(130deg);
    }
  }

  &:hover {
    .close-icon {
      opacity: 1;
    }
  }
}
