.card-description {
  padding: 0 18px;
  font-size: 12px;
  line-height: 18px;

  // client card specific
  .all-clients-wrp & {
    color: $grey-500;

    .card-description-inner-wrp  {
      padding: 18px 0;
      // border-top: 1px solid $card-border-color;
    }
  }

  // leaves card specific
  .my-leaves-wrp & {
    .leave-description-inner-wrp {
      padding: 18px 0;
    }
  }
}