.card-stats {
  display: flex;
  border-top: 1px solid $card-border-color;

  .stat-item {
    flex: 1 0 33%;
    padding: 32px 18px;
    font-size: 12px;
    font-weight: 600;
    border-right: 1px solid $card-border-color;
    text-align: center;

    &:last-child {
      border: none;
    }

    .count-box {
      display: block;
      font-size: 24px;
      font-weight: 800;
      line-height: normal;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 56px;
    }
  }
}
