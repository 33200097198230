label {
  font-size: 16px;
  line-height: 22px;
  font-weight: $font-extrabold;
  color: $grey-500;
  margin-bottom: 15px;

  &.form-check-label {
    font-weight: $font-semibold;
    font-size: 14px;
    color: $secondary;
    margin: 0;
    line-height: normal;
    display: inline-block;
    margin-left: 10px;
  }
}
