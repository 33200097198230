.tasks-wrp {
  .tasks-items-wrp {
    overflow: auto;

    .accordion {
      overflow: hidden;
      border-radius: 10px;
      border: 1px solid $card-border-color;
      min-width: 1250px;

      .tasks-heading-wrp {
        background: $white;
        display: flex;
        border-bottom: 1px solid $card-border-color;
        font-size: 16px;
        font-weight: 600;
        color: $grey-500;

        .main-title-wrp {
          flex: 1 0 38%;
          display: flex;
          align-items: center;
          padding-left: 20px;
        }

        .sub-title-wrp {
          flex: 1 0 62%;
          display: flex;
          align-items: center;

          .assignee,
          .due-date,
          .status-wrp,
          .estimate,
          .spent,
          .priority {
            flex: 1 0 10%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 13px 10px;
            border-right: 1px solid $card-border-color;
          }

          .assignee {
            border-left: 1px solid $card-border-color;
          }

          .due-date {
            flex: 1 0 12%;
          }

          .priority {
            border: none;
          }
        }
      }

      .accordion-item {
        overflow: hidden;
        border: none;
        border-top: 1px solid $card-border-color;

        &:nth-child(2) {
          border-top: none;
        }

        .accordion-header {
          .task-header {
            background-color: $card-footer-bg;
            // overflow: hidden;
            cursor: pointer;
            position: relative;
            z-index: 1;

            &::before {
              content: "";
              position: absolute;
              top: 38px;
              left: 30px;
              width: 1px;
              height: 100vh;
              background: $card-border-color;
            }

            &.collapsed {
              background: $white;

              &::before {
                height: 0;
              }

              .task-content-wrp {
                position: relative;
                z-index: 1;

                .accordion-icon {
                  &::before {
                    content: "";
                    width: 9px;
                    height: 2px;
                    display: block;
                    background: #b5b2b2;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 1;
                  }

                  &::after {
                    content: "";
                    width: 2px;
                    height: 9px;
                    display: block;
                    background: #b5b2b2;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 1;
                    opacity: 1;
                  }
                }

                .add-task-wrp {
                  padding: 20px 0;
                  font-weight: 600;
                  color: $grey-500;
                  padding-left: 50px;
                  width: 100%;
                  cursor: pointer;
                }
              }
            }

            .task-content-wrp {
              position: relative;
              z-index: 1;

              .accordion-icon {
                position: absolute;
                top: 50%;
                left: 20px;
                z-index: 1;
                width: 20px;
                height: 20px;
                transform: translateY(-50%);
                border: 2px solid $card-border-color;
                border-radius: 3px;

                &::before {
                  content: "";
                  width: 9px;
                  height: 2px;
                  display: block;
                  background: #b5b2b2;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  z-index: 1;
                }

                &::after {
                  opacity: 0;
                }
              }
            }
          }

          &:hover {
            .task-content-wrp {
              .task-name-wrp {
                .btn-wrp {
                  opacity: 1;
                }
              }
            }
          }
        }

        .accordion-collapse {
          background-color: $card-footer-bg;

          .accordion-body {
            padding: 0;

            .task-content-wrp {
              // border-top: 1px solid $card-border-color;
              position: relative;
              z-index: 1;

              &::before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                z-index: 1;
                width: 97.6%;
                height: 1px;
                background: $card-border-color;
              }

              .add-task-wrp {
                padding: 20px 0;
                font-weight: 600;
                color: $grey-500;
                padding-left: 50px;
                width: 100%;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
