.btn {
  padding: 14px 20px;
  line-height: normal;
  border-radius: 25px;
  font-size: 14px;
  font-weight: 700;
  min-width: 153px;
  border-width: 2px;

  // disable
  &:disabled {
    background: $grey-200 !important;
    border-color: $grey-200;
    color: $grey-400;
    pointer-events: none;
  }

  &:hover {
    box-shadow: 0px 5px 10px $grey-300;
  }

  &.btn-primary {
    background-color: $primary;
    color: $secondary;
    border-color: $primary;

    &:hover {
      background-color: $primary-dark;
    }
  }

  &.btn-secondary {
    background-color: $secondary;
    color: $white;
    border-color: $secondary;

    &:hover {
      background-color: $secondary-dark;
    }
  }

  &.btn-outline {
    background-color: $white;
    color: $grey-700;
    border-color: $grey-500;

    &:hover {
      background-color: $grey-300;
      border-color: $grey-500;
    }
  }

  &.btn-table {
    border: 1px solid #DFE0E2;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 600;
    color: #697C8A;
    padding: 6px 12px;
    min-width: 85px;
    box-shadow: 0px 2px 5px #0000000D;

    &:hover {
      color: $secondary;
      background-color: $grey-200;
    }

    &.large {
      padding: 15px 20px;
      font-size: 14px;
      background-color: $white;

      &:hover {
        background-color: $grey-200;
      }
    }
  }

  &.btn-edit {
    position: relative;
    z-index: 1;
    border-color: $secondary;
    padding: 0;
    width: 40px;
    height: 40px;
    min-width: auto;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      transform: translate(-50%, -50%);
      width: 16px;
      height: 16px;
      background-image: url($edit);
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &:active {
      border-color: $body-border-color;
    }
  }
}

// colored btn styles
.colored-btn {
  box-shadow: 0px 2px 5px #0000000d;
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 14px 20px;
  display: inline-block;
  text-decoration: none;

  @include custom-transition(all);

  &.success {
    background-color: $green-100;
    color: $green;
    border-color: $green-200;

    &:hover {
      background-color: $green-200;
      border-color: $green;
    }
  }

  &.gentle {
    background-color: $blue-100;
    color: $blue-300;
    border-color: $blue-200;

    &:hover {
      background-color: $blue-200;
      border-color: $blue-300;
    }
  }

  &.warning {
    background-color: $red-100;
    color: $red-300;
    border-color: $red-200;

    &:hover {
      background-color: $red-200;
      border-color: $red-300;
    }
  }

  &.with-icon {
    padding-left: 40px;
    position: relative;
    z-index: 1;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 20px;
      z-index: 1;
      transform: translateY(-50%);
      width: 10px;
      height: 8px;
      background-image: url($tick);
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &.complete {
      &::after {
        background-image: url($tick);
        width: 10px;
        height: 8px;
        left: 20px;
      }
    }

    &.clock {
      padding-left: 46px;

      &::after {
        background-image: url($timer);
        width: 16px;
        height: 16px;
      }
    }

    &.delete {
      padding-left: 46px;

      &::after {
        background-image: url($trash);
        width: 16px;
        height: 16px;
      }
    }
  }

  // only icon
  &.only-icon {
    padding: 24px 27px;
    position: relative;
    z-index: 1;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      background-image: url($trash);
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 1;
    }

    &.delete {
      background-color: $red-100;
      border-color: $red-200;

      &::after {
        background-image: url($trash);
      }
    }

    &.success {
      &::after {
        background-image: url($tick);
        width: 16px;
        height: 13px;
      }
    }

    &.clock {
      background-color: $blue-100;
      border-color: $blue-200;

      &::after {
        background-image: url($timer);
        width: 17px;
        height: 20px;
      }
    }
  }

  // disable
  &:disabled {
    background: $grey-200 !important;
    border-color: $grey-200;
    color: $grey-400;
    font-weight: 600;
    pointer-events: none;

    &.with-icon {
      &.delete {
        &::after {
          background-image: url($trash-disabled);
        }
      }
    }
  }
}
