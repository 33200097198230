.notification {
  padding: 20px 60px 20px 75px;
  display: inline-block;
  box-shadow: 0px 20px 20px #0000000D;
  border-radius: 100px;
  border: 3px solid $white;
  background-color: $blue-100;
  color: $secondary;
  font-size: 24px;
  font-weight: $font-bold;
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 30px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    transform: translateY(-50%);
    background-color: $blue;
    z-index: 1;
  }

  &.online {
    background-color: $green-100;

    &::after {
      background-color: $green;
    }
  }

  &.sign-off {
    background-color: $orange-light;

    &::after {
      background-color: $orange;
    }
  }

  &.offline {
    background-color: $red-100;

    &::after {
      background-color: $red;
    }
  }
}
