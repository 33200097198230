.timeline-outer-wrp {
  overflow: auto;
  overflow-x: hidden;
  height: calc(100vh - 540px);
  width: calc(100% + 13px);

  .timeline-box {
    box-shadow: 0px 2px 5px #0000000d;
    border: 1px solid $card-border-color;
    border-radius: 10px;
    overflow: hidden;
    max-width: 280px;
    width: 100%;
    background-color: $white;

    .timeline-item {
      display: flex;
      padding: 20px 13px;
      border-top: 1px solid $card-border-color;

      .image-wrp {
        margin-right: 10px;
      }

      .content-wrp {
        .title {
          font-size: 14px;
          font-weight: 800;
          display: inline-block;
          margin-bottom: 5px;
        }

        .description {
          font-size: 12px;
          font-weight: 600;
          line-height: 17px;
          color: $grey-600;
          margin-bottom: 10px;
        }

        .tracked-time {
          font-size: 12px;
          font-weight: 600;
        }
      }

      &:first-child {
        border: none;
      }
    }
  }
}
