.settings {
  padding: 20px;

  .setting-block {
    max-width: 342px;
    flex: 1 0 20%;

    .setting-block-inner-wrp {
      margin: 0 10px;

      .title-wrp {
        margin-bottom: 20px;

        h2 {
          margin-bottom: 20px;
        }
      }

      .items-content-wrp {
        .accordion-item {
          position: relative;
          z-index: 1;

          &.not-started {
            background: $grey-200;

            .accordion-header {
              .header {
                .accordion-icon {
                  border: 1px solid $grey-500;
                }
              }
            }
          }

          &.in-progress {
            background: $blue-100;

            .accordion-header {
              .header {
                .accordion-icon {
                  border: 1px solid $blue-300;
                }
              }
            }
          }

          &.onhold,
          &.qa-ready {
            background-color: $yellow-200;

            .accordion-header {
              .header {
                .accordion-icon {
                  border: 1px solid $yellow-400;
                }
              }
            }
          }

          &.completed {
            background-color: $green-100;

            .accordion-header {
              .header {
                .accordion-icon {
                  border: 1px solid $green;
                }
              }
            }
          }

          &.not-fixed {
            background-color: $red-100;

            .accordion-header {
              .header {
                .accordion-icon {
                  border: 1px solid $red-300;
                }
              }
            }
          }

          &.urgent {
            background-color: $red-light;
            border-color: $red-light;

            .accordion-header {
              .header {
                .name {
                  color: $white;
                }

                .accordion-icon {
                  border: 1px solid $white;

                  &::after {
                    filter: invert(1);
                  }
                }
              }
            }

            .accordion-body {
              .content-row {
                color: $white;

                span {
                  color: $white;
                }
              }
            }
          }

          &.high {
            background-color: $primary;
            border-color: $primary;

            .accordion-header {
              .header {
                .accordion-icon {
                  border: 1px solid $secondary;
                }
              }
            }
          }

          &.medium {
            background-color: $blue-light;
            border-color: $blue-light;

            .accordion-header {
              .header {
                .accordion-icon {
                  border: 1px solid $secondary;
                }
              }
            }
          }

          &.low {
            background-color: $green-light;
            border-color: $green-light;

            .accordion-header {
              .header {
                .accordion-icon {
                  border: 1px solid $secondary;
                }
              }
            }
          }

          &.gold {
            background-color: $gold;
            border-color: $gold;

            .accordion-header {
              .header {
                .accordion-icon {
                  border: 1px solid #d0af00;
                }
              }
            }
          }

          &.silver {
            background-color: $silver;
            border-color: $silver;
          }

          .accordion-header {
            cursor: pointer;

            .header {
              padding: 20px;

              .accordion-icon {
                display: inline-block;
                width: 20px;
                height: 20px;
                border: 1px solid $grey-300;
                border-radius: 50%;
                position: absolute;
                top: 20px;
                right: 20px;
                z-index: 1;

                &::after {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%) rotate(180deg);
                  width: 10px;
                  height: 10px;
                  display: block;
                  background-image: url($angle-down);
                  background-repeat: no-repeat;
                  background-size: cover;

                  @include custom-transition(transform);
                }
              }

              &.collapsed {
                .accordion-icon {
                  &::after {
                    transform: translate(-50%, -50%) rotate(0);
                  }
                }
              }

              .name {
                color: $secondary;
                font-weight: 600;
              }
            }
          }

          .accordion-body {
            padding-bottom: 30px;

            .content-row {
              margin: 5px 0;

              span {
                color: $secondary;
                font-weight: 600;
              }
            }

            .btn-wrp {
              display: flex;
              justify-content: space-between;
              margin-top: 20px;

              > div {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  .project-blocks-wrp,
  .task-blocks-wrp {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
  }

  .task-blocks-wrp {
    margin-top: 60px;
  }
}

// setting modal styles
.settings-form {
  padding: 50px;

  select.form-select {
    height: 50px;
    border-color: $grey-300;
    font-size: 14px;
    color: $secondary;
    font-weight: 600;
    padding: 10px 20px;
  }

  .button-wrp {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
  }
}
