.view-project-wrp {
  .middle-content {
    .top-form-wrp {
      .form-row {
        padding-right: 20px;

        .form-group {
          &.button-wrp {
            max-width: 50% !important;
          }
        }
      }
    }

    .middle-content-inner-wrp {
      height: calc(100vh - 200px) !important;
    }
  }
}