// common classes
.h-full {
  height: $height-full;
}
// common classes

html,
body,
#root,
#root > * {
  height: 100%;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  scroll-behavior: smooth;
}

body {
  font-family: $manrope-font;
  font-weight: 500;
  color: $secondary;
  background-color: $body-bg-color;
  font-size: 14px;
  position: relative;
  z-index: 1;
}

// scrollbar styles
/* scrollbar Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #e2e1de $white;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  background: $white;
}

*::-webkit-scrollbar-thumb {
  background-color: #e2e1de;
  border-radius: 30px;
}

// page loader styles
.page-loading {
  position: relative;
  z-index: 1;
  height: 100%;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    display: block;
    width: 100%;
    height: 100%;
    background: $secondary;
    opacity: 0.5;
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    z-index: 4;
    background-image: url($page-loader);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    animation: spin 2s linear infinite;
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.html-desc {
  min-height: 38px;
  background-color: #e9ecef;
  opacity: 1;
  border-radius: 10px;
  padding: 15px;
  
  img {
    max-width: 100%;
  }
}

.ql-container {
  min-height: 10rem;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ql-editor {
  height: 100%;
  flex: 1;
  overflow-y: auto;
  width: 100%;
}

.html-desc {
  min-height: 38px;
  background-color: #e9ecef;
  opacity: 1;
}

.html-desc img {
  max-width: 100%;
}