.add-project,
.view-project {
  .form-wrp {
    form {
      .form-inner-wrp {
        .form-content-wrp {
          display: flex;
          align-items: flex-start;
          overflow: auto;

          .form-left-col {
            flex: 1 0 25%;
            max-width: 330px;

            .logo-label {
              margin-bottom: 15px;
            }

            .logo-wrp {
              border: 1px solid $grey-300;
              border-radius: 5px;
              width: 100%;
              height: 125px;
              background-color: $grey-200;
              display: flex;
              align-items: center;
              justify-content: center;
              overflow: hidden;
              position: relative;
              z-index: 1;

              &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: -1;
                width: 58px;
                height: 49px;
                background-image: url($logo-upload);
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                transform: translate(-50%, -50%);
              }

              &.project-logo-wrp {
                background-color: transparent;
                border: none;
              }

              img.project-logo {
                display: block;
                object-fit: contain;
                width: 100%;
                height: 100%;
              }
            }
          }

          .form-right-col {
            flex: 1 0 auto;
            padding-right: 20px;
            border-left: 1px solid $body-border-color;

            @include media-breakpoint-up(xxl) {
              padding-right: 270px;
            }

            .title-wrp {
              h2 {
                font-size: 20px;
                font-weight: 800;
                line-height: normal;
              }

              p {
                font-size: 12px;
                font-weight: 600;
              }
            }

            .project-detail-row {
              display: flex;

              &.pd-first {
                padding-bottom: 40px;
                margin-bottom: 40px;
                border-bottom: 1px solid $body-border-color;

                .form-group {
                  margin-bottom: 15px;
                }

                &.cd-first {
                  border: none;
                }
              }

              &.pd-second {
                .form-group {
                  margin-bottom: 15px;
                }

                &.cd-second {
                  .form-check {
                    margin-top: 38px;
                  }
                }
              }

              > .form-group {
                margin: 0;
              }

              .form-group-wrp {
                max-width: 330px;
                margin-right: 36px;

                .form-group {
                  &:last-child {
                    margin: 0;
                  }
                }
              }

              .description-col {
                padding-left: 4px;
              }
            }
          }
        }

        .button-wrp {
          border-top: 1px solid $body-border-color;
          padding: 23px 0;
          display: flex;
          justify-content: flex-end;
          padding-right: 270px;
          background-color: #f7f7f7;
        }
      }
    }
  }
}

.add-project {
  .form-inner-wrp {
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .form-content-wrp {
      height: 100%;

      .form-left-col {
        padding: 0 20px;
        padding-top: 25px;

        .form-group {
          margin-bottom: 10px;

          label {
            font-size: 12px;
            line-height: 18px;
          }

          > input {
            padding: 10px 15px;
            font-size: 12px;
            line-height: 18px;
            height: auto !important;
          }

          .basic-single {
            box-shadow: 0px 2px 5px $grey-200;

            .select__control {
              height: 40px;
              border-color: $grey-300;
              box-shadow: none;
            }
          }

          .form-control {
            height: 40px;
          }

          .select__value-container {
            padding: 0 15px;
          }

          .logo-wrp {
            width: 100%;

            img {
              display: none;
            }
          }
        }
      }

      .form-right-col {
        height: 100%;
        padding-left: 20px;
        padding-top: 25px;
        padding-bottom: 0;

        input:not(.form-check-input)   {
          padding: 10px 15px;
          font-size: 12px;
          line-height: 18px;
          height: auto !important;
        }

        .basic-single {
          box-shadow: 0px 2px 5px $grey-200;

          .select__control {
            height: 40px;
            border-color: $grey-300;
            box-shadow: none;
          }
        }

        .title-wrp {
          margin-bottom: 20px;
        }

        .project-detail-row {
          &.pd-first {
            padding-bottom: 0 !important;
            margin-bottom: 20px !important;
          }
        }
      }
    }

    .button-wrp {
      padding: 11px 270px 11px 0 !important;
    }
  }
}

.view-project {
  .form-wrp {
    form {
      .form-inner-wrp {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .form-content-wrp {
          height: 100%;

          .form-left-col {
            padding: 0 40px;
            padding-top: 45px;
          }

          .form-right-col {
            padding-top: 45px;
            padding-left: 40px;
            padding-bottom: 115px;
            padding-right: 40px;
            height: 100%;

            @media (max-height: 1010px) {
              height: auto;
            }
          }

          .title-wrp {
            margin-bottom: 38px;
          }
        }

        .button-wrp {
          display: flex;
          justify-content: space-between;
          padding: 23px 40px;
        }
      }
    }
  }
}
