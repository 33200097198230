.navbar {
  background-color: $body-bg-color;
  border-bottom: 1px solid $body-border-color;
  padding: 0 20px;
  min-height: 100px;
  z-index: 2;

  .content-wrp {
    display: flex;
    width: 100%;
  }

  // variation 2 styles
  .variation-2 {
    .content-wrp {
      justify-content: space-between;
    }
  }

  // variation 3 styles
  .variation-3 {
    .content-wrp {
      align-items: center;
      justify-content: space-between;

      .project-logo-name-wrp {
        display: flex;
        align-items: center;

        .project-logo {
          margin-right: 25px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .project-content {
        display: flex;
        align-items: center;

        .tagline-wrp {
          margin-right: 15px;
        }
      }
    }
  }

  // variation 4 styles
  .variation-4 {
    .content-wrp {
      align-items: center;
      justify-content: space-between;

      .project-logo-name-wrp {
        display: flex;
        align-items: center;

        .tier-wrp {
          margin-left: 20px;
        }
      }
    }
  }
}
