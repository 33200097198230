.my-leaves-wrp {
  height: calc(100% - 120px);

  .leaves-wrp {
    height: calc(100vh - 190px);
    overflow: hidden;
    border-top: 1px solid $body-border-color;

    .leaves-inner-wrp {
      display: flex;
      margin-left: -20px;
      // overflow-x: auto;
      scrollbar-width: none;  /* Firefox */
      max-width: 100%;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;  /* Safari and Chrome */
      }

      .leaves-column {
        flex: 1 0 auto;
        max-width: 384px;
        width: 100%;
        overflow: hidden;
        position: relative;
        border-right: 1px solid $body-border-color;
        padding-top: 20px;

        // &.long-content {
        //   &::after {
        //     content: "";
        //     position: absolute;
        //     width: 100%;
        //     bottom: 0;
        //     left: 0;
        //     height: 117px;
        //     background: transparent linear-gradient(180deg, #FFFFFF4F 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
        //     z-index: 2;
        //   }

        //   &::before {
        //     content: "";
        //     position: absolute;
        //     width: 50px;
        //     height: 50px;
        //     left: 50%;
        //     transform: translateX(-50%);
        //     bottom: 25px;
        //     z-index: 3;
        //     box-shadow: 0px 2px 5px #0000000D;
        //     border: 1px solid $grey-50;
        //     border-radius: 50%;
        //     background-color: $white;
        //     background-image: url($angle-down);
        //     background-position: 50% 60%;
        //     background-repeat: no-repeat;
        //     background-size: 50% 50%;
        //   }
        // }

        .leave-month-counter {
          max-width: 344px;
          width: 100%;
          box-shadow: 0px 2px 5px #0000000D;
          border: 1px solid $card-border-color;
          border-radius: 10px;
          overflow: hidden;
          background: $white;
          margin: 0 auto;
          min-height: 58px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: $font-extrabold;
          font-size: 16px;
          padding: 10px 10px 10px 20px;
          margin-bottom: 20px;
          color: $grey-500;

          .counter {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            box-shadow: 0px 2px 5px #0000000D;
            border: 1px solid $card-border-color;
            border-radius: 10px;
            color: $secondary;
          }
        }

        .leave-item-wrp {
          scrollbar-width: none;  /* Firefox */
          height: calc(100vh - 280px);
          overflow: hidden;

          .scroll-up-button {
            position: absolute;
            top: 0;
          }

          .scroll-down-button {
            position: absolute;
            bottom: 50px;
          }

          &::-webkit-scrollbar {
            display: none;  /* Safari and Chrome */
          }
        }
      }
    }
  }
}