.card-header {
  border-radius: 0;
  background: none;
  border: none;
  padding: 18px;
  display: flex;
  align-items: flex-start;

  .project-logo {
    margin-right: 10px;
    flex: 1 0 auto;
    max-width: 40px;
  }

  .project-content {
    .project-name {
      margin: 5px 0;
    }

    .tagline-wrp {
      margin: 0 -2px;

      .tagline {
        display: inline-block;
      }
    }
  }

  // client card specific
  .all-clients-wrp & {
    align-items: center;
    border-bottom: 1px solid $card-border-color;

    .client-logo {
      margin-right: 10px;

      .avatar-outer-wrp {
        display: block;
      }
    }

    .client-tier {
      margin-left: auto;
    }
  }

  // leaves card specific
  .my-leaves-wrp & {
    align-items: center;
    border-bottom: 1px solid $card-border-color;

    .leave-title {
      margin-right: 5px;
    }
  }
}
