.weekly-timelog-wrapper {
.weekly-time-logs {
  max-width: calc(100vw - 340px);

  .daily-time-logs-table-wrp {
    overflow-x: auto;

    .daily-time-logs-table {
      min-height: 220px;
      overflow: auto;
      border-radius: 10px;
      border: 1px solid $card-border-color;
      min-width: 1612px;

      .tasks-heading-wrp,
      .daily-tl-item {
        background: $white;
        display: flex;
        border-bottom: 1px solid $card-border-color;

        .project {
          flex: 1 0 10%;
          display: flex;
          padding: 13px 20px;
          border-right: 1px solid $card-border-color;
        }

        .task {
          flex: 1 0 10%;
          padding: 13px 20px;
          border-right: 1px solid $card-border-color;
        }

        .date {
          flex: 1 0 1%;
          padding: 13px 20px;
          border-right: 1px solid $card-border-color;
        }
      }

      .tasks-heading-wrp {
        font-size: 16px;
        font-weight: 600;
        color: $grey-500;
      }

      .daily-tl-item-wrp {
        .daily-tl-item {
          &:last-child {
            border: none;
          }

          .actions {
            .action-icon-wrp {
              display: flex;
              gap: 20px;

              .action-item {
                width: 16px;

                a {
                  display: block;

                  img {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

  form {
    max-width: calc(100vw - 340px);

    .form-row {
      margin-bottom: 20px;

      .form-group {
        margin: 9px 0 !important;
        max-width: 260px !important;
      }
    }
  }
}
