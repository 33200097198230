.my-time-logs {
  .middle-content {
    overflow: hidden;

    .tab-section-wrp {
      .tabbed-btn-wrp {
        position: absolute;
        right: 20px;
      }
    }
  }
}