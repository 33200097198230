.view-task {
  .form-inner-wrp {
    height: 100vh;

    .form-content-wrp {
      display: flex;
      height: 100%;

      .form-left-col {
        flex: 1 0 auto;
        max-width: 620px;
        min-width: 620px;
        position: relative;
        z-index: 1;
        height: 100%;

        .form-left-inner-wrp {
          // margin-top: 30px;

          .view-task-header {
            display: flex;
            align-items: center;
            padding-right: 30px;
            padding-top: 25px;
            padding-bottom: 25px;

            .task-priority {
              .priority-flag {
                background-image: url($flag-white);
                background-position: 30% center;
                background-repeat: no-repeat;
                background-size: 35% 100%;
                width: 40px;
                height: 50px;
                display: block;
                border-radius: 0 25px 25px 0;
                margin-right: 20px;

                &.flag-yellow {
                  background-color: #ffd73e;
                }

                &.flag-default,
                &.flag-blue {
                  background-color: $blue-light;
                }

                &.flag-red {
                  background-color: #e02121;
                }

                &.flag-green {
                  background-color: #3ed823;
                }
              }
            }

            .task-name {
              font-size: 20px;
              font-weight: 800;
            }

            .task-status {
              margin-left: auto;
            }
          }

          .view-task-detail {
            display: flex;
            background-color: $grey-50;
            border-top: 1px solid $body-border-color;
            border-bottom: 1px solid $body-border-color;

            .assignee-wrp,
            .track-time-wrp {
              padding: 15px 30px;
            }

            .assignee-wrp {
              flex: 1 0 45%;
              display: flex;
              // justify-content: space-between;
              border-right: 1px solid $body-border-color;

              > div {
                margin-right: 20px;
              }

              label {
                font-size: 14px;
              }

              .task-owner,
              .task-qa {
                .image-wrp {
                  .avatar-outer-wrp {
                    .avatar-wrp {
                      border: 2px solid #f5f4df;
                    }
                  }
                }
              }

              .task-assignees {
                .image-wrp {
                  padding-left: 6px;

                  .avatar-outer-wrp {
                    margin-left: -6px;

                    .avatar-wrp {
                      border: 2px solid #f5f4df;
                    }
                  }
                }
              }
            }

            .track-time-wrp {
              flex: 1 0 55%;

              .track-time-inner-wrp {
                box-shadow: 0px 2px 5px #0000000d;
                border: 1px solid $green-200;
                border-radius: 10px;
                display: flex;

                .track-time {
                  flex: 1 0 auto;
                  text-align: center;
                  padding: 6px 10px;

                  .time {
                    font-size: 24px;
                    font-weight: 800;
                    line-height: normal;
                  }

                  label {
                    font-size: 12px;
                    font-weight: 600;
                    margin: 0;
                    color: $secondary;
                    display: block;
                    line-height: normal;
                  }

                  &.billable {
                    background-color: $green-100;
                    border-left: 1px solid $green-200;
                    border-right: 1px solid $green-200;
                  }
                }
              }
            }
          }

          .description-detail-wrp {
            padding: 30px;

            .timeline-wrp {
              margin: 0 -5px;
              margin-bottom: 30px;
              display: flex;
              justify-content: space-between;

              .date-timeline {
                flex: 1 0 50%;
                padding: 0 5px;

                label {
                  font-size: 14px;
                  margin-bottom: 4px;

                  .remaining-days {
                    background-color: #ececec;
                    padding: 5px 7px;
                    border-radius: 5px;
                    color: $secondary;
                    font-size: 12px;
                    font-weight: 600;
                    display: inline-block;
                    line-height: normal;
                    margin-left: 12px;
                  }

                  .behind-days {
                    background-color: #ffe0e0;
                    padding: 5px 7px;
                    border-radius: 5px;
                    color: $secondary;
                    font-size: 12px;
                    font-weight: 600;
                    display: inline-block;
                    line-height: normal;
                    margin-left: 12px;
                  }
                }

                .date {
                  font-size: 14px;
                  font-weight: 600;
                }
              }

              .file-upload-wrp {
                flex: 1 0 50%;
                padding: 0 5px;

                label {
                  font-size: 14px;
                  margin-bottom: 4px;
                }

                .upload-file-wrp {
                  margin: 0 -5px;

                  .upload-file-item {
                    background-color: #ececec;
                    padding: 5px 7px;
                    border-radius: 5px;
                    color: $secondary;
                    font-size: 12px;
                    font-weight: 600;
                    display: inline-block;
                    line-height: normal;
                    margin: 5px;
                    text-decoration: none;
                  }
                }
              }

              .file-upload-outer-wrp {
                .file-upload-wrp {
                  margin-top: 15px;

                  &:first-child {
                    margin-top: 0;
                  }

                  .upload-wrp {
                    input[type="file"] {
                      width: 100%;
                      background: $grey-100;
                      border: 1px solid $grey-300;
                      border-radius: 5px;
                      line-height: normal;

                      &::-webkit-file-upload-button {
                        display: none;
                      }

                      &::file-selector-button {
                        display: none;
                      }
                    }
                  }
                }
              }
            }

            .description-wrp {
              padding-bottom: 90px;

              .description-block {
                padding: 16px 20px;
                background-color: $grey-50;
                border-radius: 5px;
                border: 1px solid $grey-100;
              }
            }
          }
        }

        .button-wrp {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          padding: 10px 20px;
          background-color: #f7f7f7;
          border-top: 1px solid $body-border-color;
          border-bottom: 1px solid $body-border-color;
          display: flex;
          justify-content: space-between;
        }
      }

      .form-right-col {
        flex: 1 1 auto;
        background-color: $grey-50;
        padding: 0 45px;
        border-left: 1px solid $body-border-color;
        position: relative;
        z-index: 1;
        overflow: hidden;

        .title-wrp {
          margin-top: 36px;

          h2 {
            font-size: 16px;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 15px;
          }
        }

        .sub-tasks-popup-wrp {
          height: calc(100vh - 120px);
          overflow: hidden;
          position: relative;
          z-index: 1;

          .sub-tasks-popup-inner-wrp {
            height: 100%;
            overflow: auto;
            position: relative;
            z-index: 1;
          }
        }

        .sub-tasks-content-wrp {
          .sub-task-accordion,
          .sub-task-wrp {
            .accordion-item {
              border: 1px solid $card-border-color;
              border-radius: 4px;
              background-color: $white;
              margin-bottom: 5px;
              position: relative;
              z-index: 1;
              will-change: margin;
              transition: margin 0.3s ease-in-out;

              .accordion-header {
                cursor: pointer;
                padding: 10px 18px;
                padding-right: 50px;

                .task-header {
                  .accordion-icon {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    border: 1px solid $grey-300;
                    border-radius: 50%;
                    position: absolute;
                    top: 15px;
                    right: 20px;
                    z-index: 1;

                    &::after {
                      content: "";
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%) rotate(180deg);
                      width: 10px;
                      height: 10px;
                      display: block;
                      background-image: url($angle-down);
                      background-repeat: no-repeat;
                      background-size: cover;

                      @include custom-transition(transform);
                    }
                  }

                  &.collapsed {
                    .accordion-icon {
                      &::after {
                        transform: translate(-50%, -50%) rotate(0);
                      }
                    }
                  }
                }
                .task-content-wrp {
                  align-items: center;

                  .avatar-title-wrp {
                    display: flex;
                    align-items: center;

                    .image-wrp {
                      display: flex;
                      align-items: center;
                      margin-right: 18px;
                    }

                    .task-name {
                      font-size: 14px;
                      font-weight: 600;
                    }
                  }

                  .status-wrp {
                    margin-left: auto;
                  }
                }
              }

              .accordion-body {
                padding: 0;

                .project-detail-row {
                  .form-outer-group-wrp {
                    .form-group-wrp {
                      .timeline-main-wrp {
                        background: $grey-50;
                        border-top: 1px solid $card-border-color;
                        border-bottom: 1px solid $card-border-color;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;

                        .timeline-priority-wrp {
                          flex: 1 1 65%;
                          padding: 18px;
                          display: flex;
                          align-items: center;

                          @include media-breakpoint-up(xxl) {
                            flex: 1 1 60%;
                            padding: 0;
                            padding-left: 18px;
                          }

                          .timeline-wrp {
                            flex: 1 0 50%;

                            label {
                              font-size: 14px;
                              margin-bottom: 4px;
                              font-weight: 800;

                              .remaining-days {
                                background-color: $card-border-color;
                                padding: 5px 7px;
                                border-radius: 5px;
                                color: $secondary;
                                font-size: 14px;
                                font-weight: 600;
                                display: inline-block;
                                line-height: normal;
                                margin-left: 12px;
                              }

                              .behind-days {
                                background-color: #ffe0e0;
                                padding: 5px 7px;
                                border-radius: 5px;
                                color: $secondary;
                                font-size: 14px;
                                font-weight: 600;
                                display: inline-block;
                                line-height: normal;
                                margin-left: 12px;
                              }
                            }

                            .date {
                              font-weight: 600;
                            }
                          }

                          .priority-wrp {
                            flex: 1 0 50%;
                            padding-left: 15px;

                            label {
                              font-size: 14px;
                              font-weight: 800;
                              line-height: normal;
                              display: inline-block;
                              margin: 0;
                            }

                            .priority {
                              font-weight: 600;
                            }
                          }
                        }

                        .track-time-wrp {
                          flex: 0 1 100%;
                          max-width: 100%;
                          padding: 18px;

                          @include media-breakpoint-up(xxl) {
                            flex: 0 1 40%;
                            border-left: 1px solid $grey-300;
                            max-width: 100%;
                          }

                          .track-time-inner-wrp {
                            box-shadow: 0px 2px 5px #0000000d;
                            border: 1px solid $grey-400;
                            border-radius: 10px;
                            display: flex;

                            .track-time {
                              flex: 1 0 auto;
                              text-align: center;
                              padding: 6px 10px;

                              .time {
                                font-size: 24px;
                                font-weight: 800;
                                line-height: normal;
                              }

                              label {
                                font-size: 12px;
                                font-weight: 600;
                                margin: 0;
                                color: $secondary;
                                display: block;
                                line-height: normal;
                              }

                              &.billable {
                                background-color: $grey-200;
                                border-left: 1px solid $grey-400;
                                border-right: 1px solid $grey-400;
                              }
                            }
                          }
                        }
                      }

                      .file-upload-wrp {
                        padding: 0 18px;
                        margin-top: 20px;

                        label {
                          font-size: 14px;
                          font-weight: 800;
                          line-height: normal;
                          display: inline-block;
                          margin: 0;
                        }

                        .upload-file-wrp {
                          margin: 0 -5px;

                          .upload-file-item {
                            background-color: #ececec;
                            padding: 5px 7px;
                            border-radius: 5px;
                            color: $secondary;
                            font-size: 12px;
                            font-weight: 600;
                            display: inline-block;
                            line-height: normal;
                            margin: 5px;
                            text-decoration: none;
                          }
                        }
                      }

                      .description-wrp {
                        padding: 0 18px;
                        margin-top: 20px;

                        label {
                          font-size: 14px;
                          font-weight: 800;
                        }

                        .description {
                          padding: 30px;
                          border: 1px solid $card-border-color;
                          border-radius: 10px;
                        }
                      }
                    }

                    .btn-wrp {
                      margin: 20px 0;
                      padding: 0 18px;
                      display: flex;

                      .btn {
                        margin-left: auto;
                      }
                    }

                    .time-tracker-wrp {
                      padding: 0 18px;
                      margin-top: 15px;

                      .form-fields-wrp {
                        .form-fields-inner-wrp {
                          display: flex;
                          flex-wrap: wrap;
                          align-items: flex-end;
                          gap: 10px;

                          .form-label {
                            font-size: 14px;
                            margin-bottom: 5px;
                          }

                          .tt-spent-time {
                            position: absolute;
                            top: 100%;
                            left: 0px;
                          }

                          .time-tracker {
                            border: 1px solid $blue-200;
                            border-radius: 10px;

                            input {
                              border: none;
                              background-color: transparent;
                              padding: 5px;
                              font-size: 20px;
                              font-weight: 900;
                              -webkit-appearance: textfield;
                              -moz-appearance: textfield;
                              appearance: textfield;
                              min-width: 20px;
                              width: auto;

                              &::-webkit-outer-spin-button,
                              &::-webkit-inner-spin-button {
                                  -webkit-appearance: none;
                                  margin: 0;
                              }
                            }

                            .middle-dots {
                              font-size: 20px;
                            }

                            .btn {
                              background-color: $blue-100;
                              border: none;
                              min-width: 50px;
                              border-radius: 0px 10px 10px 0px;
                              width: 50px;
                              height: 50px;
                              padding: 14px 16px;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                              margin: 0;
                            }
                          }

                          .time-tracker-non-billable {
                            border: 1px solid $grey-300;
                            border-radius: 10px;

                            input {
                              border: none;
                              background-color: transparent;
                              padding: 5px;
                              font-size: 20px;
                              font-weight: 900;
                              -webkit-appearance: textfield;
                              -moz-appearance: textfield;
                              appearance: textfield;
                              min-width: 20px;
                              width: auto;

                              &::-webkit-outer-spin-button,
                              &::-webkit-inner-spin-button {
                                  -webkit-appearance: none;
                                  margin: 0;
                              }
                            }

                            .middle-dots {
                              font-size: 20px;
                            }
                          }

                          .track-notes {
                            flex-grow: 1;
                          }

                          .task-status {
                            flex-grow: 1;
                          }

                          .date-wrap {
                            @include media-breakpoint-down(xl){
                              flex-grow: 1;
                            }
                          }

                          &:first-child {
                            @include media-breakpoint-up(xl) {
                              margin-bottom: 15px;
                            }
                          }
                        }
                      }
                    }

                    .comment-error {
                      position: absolute;
                      top: 100%;
                      left: 26px;
                      z-index: 1;
                    }

                    .comment-buttons-wrp {
                      .comment-btn {
                        min-width: auto;
                        border: none;
                        box-shadow: none;

                        &:hover svg {
                          transform: scale(1.1);
                        }

                        svg {
                          width: 15px;
                          height: 14px;

                          @include custom-transition(transform);
                        }

                        &.btn-secondary {
                          background-color: transparent;

                          svg {
                            path {
                              fill: $secondary;
                            }
                          }
                        }

                        &.delete {
                          background-color: transparent;

                          svg {
                            path {
                              fill: $red;
                            }
                          }
                        }

                        &.set-2 {
                          min-width: 100px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          &.add-subtask-active {
            .accordion-item {
              margin-top: -50px;

              &:first-child {
                margin-top: 0;
              }
            }
          }
        }

        .add-subtask-btn {
          background: $white;
          border: none;
          width: 100%;
          padding: 15px 0;
          border: 1px dashed $grey-300;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 600;
          color: $grey-500;
          display: block;
          transition: all 0.3s ease-in-out;

          &.show-sub-task-form {
            opacity: 0;
          }
        }

        // add/edit sub task style
        .add-sub-task,
        .edit-sub-task {
          position: absolute;
          z-index: 1;
          opacity: 0;
          transition: all 0.3s ease-in-out;
          width: 100%;
          top: 80px;
          padding: 0 45px;
          left: 0;
          pointer-events: none;
          transform: translateY(10%);

          &.edit-subtask-active {
            opacity: 1;
            pointer-events: all;
            transform: translateY(0%);
          }

          &.show-sub-task-form {
            opacity: 1;
            pointer-events: all;
            transform: translateY(0%);
          }

          .form-wrp {
            .form-fields-wrp {
              height: calc(100vh - 180px);
              overflow: hidden;

              @include media-breakpoint-up(xxl) {
                height: calc(100vh - 200px);
              }

              .form-fields-inner-wrp {
                border: 1px solid $card-border-color;
                border-radius: 4px;
                padding: 45px 30px 30px;
                background-color: $white;
                height: 100%;
                overflow: auto;

                .form-label {
                  margin-bottom: 5px;

                  @include media-breakpoint-up(xxl) {
                    margin-bottom: 15px;
                  }
                }

                #sub_task_description {
                  height: 120px !important;

                  @include media-breakpoint-up(xxl) {
                    min-height: 570px;
                  }
                }

                .upload-wrp {
                  width: 100%;
                  height: 50px;

                  input[type="file"] {
                    height: 100%;
                    width: 100%;
                    background: $grey-100;
                    border: 1px solid $grey-300;
                    border-radius: 5px;
                    line-height: normal;

                    &::-webkit-file-upload-button {
                      display: none;
                    }

                    &::file-selector-button {
                      display: none;
                    }
                  }
                }

                .file-upload-wrp {
                  .upload-file-wrp {
                    margin: 0 -5px;

                    .upload-file-item {
                      background-color: $card-border-color;
                      padding: 5px 7px;
                      border-radius: 5px;
                      color: $secondary;
                      font-size: 12px;
                      font-weight: 600;
                      display: inline-block;
                      line-height: normal;
                      margin: 5px;
                      text-decoration: none;
                    }
                  }
                }
              }
            }

            .button-wrp {
              display: flex;
              justify-content: space-between;
              padding-top: 20px;
              background-color: $grey-50;

              .del-update-btn-wrp {
                .btn-table {
                  margin-left: 10px;
                }
              }
            }
          }
        }

        // sub tasks styles
        .sub-task-wrp {
          position: absolute;
          z-index: 1;
          opacity: 0;
          transition: all 0.3s ease-in-out;
          width: 100%;
          top: 7px;
          left: 0;
          opacity: 0;
          pointer-events: none;
          transform: translateY(10%);
          animation-name: up-slow-sub;
          animation-duration: 0.25s;
          animation-fill-mode: forwards;
        }
      }
    }
  }
}

@keyframes up-slow-sub {
  from {
    opacity: 0;
    pointer-events: none;
    transform: translateY(10%);
  }
  to {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0%);
  }
}