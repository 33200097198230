.priority-icon {
  display: inline-block;
  width: 18px;
  height: 20px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;

  &.flag-blue {
    background-image: url($flag-blue);
  }

  &.flag-yellow {
    background-image: url($flag-yellow);
  }

  &.flag-red {
    background-image: url($flag-red);
  }

  &.flag-green {
    background-image: url($flag-green);
  }

  &.flag-default {
    background-image: url($flag-blue);
  }
}
