.floating-time-wrp {
  max-width: 480px;
  position: fixed;
  bottom: -20px;
  opacity: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background-color: $white;
  box-shadow: 0px 0px 40px #00000029;
  border-radius: 58px;
  z-index: 3;
  animation-name: up-slow;
  animation-duration: 0.25s;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;

  .floating-time-inner-wrp {
    padding: 25px 35px;
    display: flex;
    align-items: center;

    .tt-spent-time {
      min-width: 150px;
      display: block;
      font-size: 20px;
      font-weight: 900;
      padding: 0 20px;
    }

    .time-tracker {
      border: 1px solid $blue-200;
      border-radius: 10px;
      max-width: 220px;
      align-items: center;

      input {
        border: none;
        background-color: transparent;
        padding: 5px;
        font-size: 20px;
        font-weight: 900;
        width: 100%;
        flex: 1;
      }

      .middle-dots {
        font-size: 24px;
      }

      .btn {
        background-color: $blue-100;
        border: none;
        min-width: 50px;
        border-radius: 0px 10px 10px 0px !important;
        width: 50px;
        height: 50px;
        padding: 14px 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 !important;
      }
    }

    .button-wrp {
      margin-left: auto;
    }
  }
}

@keyframes up-slow {
  from {
    bottom: -20px;
    opacity: 0;
  }
  to {
    bottom: 20px;
    opacity: 1;
  }
}