.ReactModal {
  &__Overlay {
    z-index: 9999;
    background-color: transparent !important;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      display: block;
      background-color: $secondary;
      opacity: 0.5;
      transition: opacity 0.5s;
    }

    &--after-open {
      &::after {
        opacity: 0.5;
      }
    }

    &--before-close {
      &::after {
        opacity: 0;
      }
    }
  }

  &__Content {
    transform: translate3d(100%, 0, 0);
    transition: transform 0.5s;
    transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
    width: 90%;
    // min-width: 70%;
    max-width: 1200px;
    height: 100%;
    min-height: 100%;

    @include media-breakpoint-up(xxl) {
      max-width: 1432px;
    }

    &--after-open {
      transform: translate3d(0, 0, 0);
    }

    &--before-close {
      transform: translate3d(100%, 0, 0);
    }

    .modal-header {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 40px;
      height: 40px;
      justify-content: center;
    }

    // view task modal styles
    &.view-task-modal {
      max-width: 1620px;
    }

    // create parent task modal styles
    &.create-parent-task-modal {
      max-width: 888px;
    }

    // setting modal style
    &.setting-modal {
      max-width: 888px;
    }
  }
}

// swal modal styles
.swal-overlay {
  background-color: rgba(5, 37, 60, 0.5);

  .swal-modal {
    width: 470px;
    padding: 40px 68px;
    border-radius: 15px;

    .swal-title {
      padding: 0;
      margin: 0 !important;
      margin-bottom: 6px !important;
      font-size: 32px;
      font-weight: 800;
      color: $secondary;
    }

    .swal-text {
      color: $grey-500;
      font-weight: 700;
      text-align: center;
    }

    .swal-footer {
      padding: 0;
      margin: 0;
      margin-top: 66px;
      display: flex;
      justify-content: space-between;

      .swal-button-container {
        margin: 0 7px;
      }
    }

    &.delete-box {
      padding-top: 210px;

      &::before {
        content: "";
        position: absolute;
        top: 75px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        width: 90px;
        height: 90px;
        background-image: url($modal-trash);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    &.success-box {
      .swal-footer {
        justify-content: space-around;
      }
    }
  }

  &.swal-overlay--show-modal {
    .delete-box {
      &::before {
        animation: tilt-shaking 0.35s;
        animation-delay: 300ms;
      }
    }
  }
}

@keyframes tilt-shaking {
  0% {
    transform: translateX(-50%) rotate(0deg);
  }
  25% {
    transform: translateX(-50%) rotate(5deg);
  }
  50% {
    transform: translateX(-50%) rotate(0eg);
  }
  75% {
    transform: translateX(-50%) rotate(-5deg);
  }
  100% {
    transform: translateX(-50%) rotate(0deg);
  }
}
