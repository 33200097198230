.tabbed-btn-wrp {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-flex;
  border: 1px solid #DFE0E2;
  border-radius: 10px;
  overflow: hidden;

  .right-sidebar-content & {
    width: 100%;
  }

  .tabbed-btn {
    padding: 16px 20px;
    border-right: 1px solid #DFE0E2;
    color: $grey-700;
    cursor: pointer;
    font-weight: $font-semibold;

    .right-sidebar-content & {
      flex: 1;
    }

    &:last-child {
      border: none;
    }

    &.active {
      color: $secondary;
      background-color: #F0F0F0;
    }

    &:hover {
      color: $secondary;
      background-color: $grey-50;
    }
  }
}
