.card-buttons {
  display: flex;

  .button-link {
    display: block;
    text-decoration: none;
    padding: 20px 18px;
    font-size: 14px;
    font-weight: 800;
    color: $secondary;
    position: relative;
    z-index: 1;
    flex: 1 0 auto;
    border-right: 1px solid $card-border-color;

    &:last-child {
      border: none;
    }

    &::after {
      content: "";
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1;
      width: 15px;
      height: 15px;
      display: block;
      background-image: url($arrow-up-new-tab);
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      transform: rotate(45deg);
      opacity: 0;

      @include custom-transition(opacity);
    }

    &:hover {
      &::after {
        opacity: 1;
      }
    }
  }
}
