.status {
  display: inline-block;
  padding: 5px 15px;
  border-radius: 15px;
  background: $grey-200;
  color: $grey-500;
  font-weight: $font-semibold;
  margin: 2px;

  &.small {
    font-size: 12px;
    padding: 2px 10px;
  }

  &.ex-small {
    font-size: 10px;
    padding: 1.5px 10px;
  }

  &.blue {
    background-color: $blue-100;
    color: $blue-300;
  }

  &.yellow {
    background-color: $yellow-200;
    color: $yellow-400;
  }

  &.red {
    background-color: $red-100;
    color: $red-300;
  }

  &.green {
    background-color: $green-100;
    color: $green;
  }
}
