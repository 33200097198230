.card-url {
  .card-url-anchor {
    display: block;
    text-decoration: none;
    padding: 20px 18px;
    position: relative;
    z-index: 1;

    &::after {
      content: "";
      position: absolute;
      top: 10px;
      right: 20px;
      z-index: 1;
      width: 15px;
      height: 15px;
      display: block;
      background-image: url($arrow-up-new-tab);
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      transform: rotate(45deg);
      opacity: 0;

      @include custom-transition(opacity);
    }

    span {
      display: block;

      &.title {
        font-size: 14px;
        font-weight: 800;
        color: $secondary;
      }

      &.link {
        font-size: 12px;
        font-weight: 600;
        color: $grey-600;
      }
    }

    &:hover {
      &::after {
        opacity: 1;
      }
    }
  }
}
