.login-wrp {
  width: 100%;
  max-width: 432px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);

  .logo {
    margin-bottom: 118px;
  }

  .card {
    box-shadow: 0px 2px 5px #0000000D;
    border: 1px solid #ECECEC;
    border-radius: 10px;
    min-width: 100%;

    .card-body {
      padding: 50px;

      .card-title {
        margin-bottom: 43px;
      }

      .form-floating {
        margin-bottom: 20px;

        .form-control {
          height: 50px;
          padding-left: 20px;
          padding-right: 20px;
        }

        label {
          padding: 14px 20px;
          line-height: normal;
          font-weight: 600;
          color: #82929D;

          &.error-message {
            position: relative;
            top: 100%;
            z-index: 2;
            left: 0;
            padding: 0;
            height: auto;
            margin: 0;
            margin-bottom: -4px;
            border: none;
            line-height: normal;
            font-size: 12px;
            color: $red;
            opacity: 1;
            transform: none;
          }
        }
      }

      .btn-wrp {
        .btn {
          width: 100%;
          border-radius: 5px;
        }
      }
    }
  }

  .reset-link-wrp {
    margin-top: 200px;

    a {
      font-weight: 500;
      font-size: 16px;
      color: $secondary;
      text-decoration: none;
      display: inline-block;
      position: relative;
      z-index: 1;

      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 1px;
        display: block;
        background-color: $secondary;

        @include custom-transition(opacity);
      }

      &:hover {
        &::after {
          opacity: 0;
        }
      }
    }
  }
}
