.main-content-wrp {
  height: 100%;

  .left-sidebar {
    border-right: 1px solid $body-border-color;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 300px;
    height: 100%;
  }

  .right-panel {
    flex: 1 0 auto;
    padding-left: 300px;
    height: 100%;

    .main-content {
      // display: flex;

      &.has-right-sidebar {
        .middle-content {
          padding-right: 330px;

          .middle-content-inner-wrp {
            height: calc(100vh - 100px);
            overflow: auto;
            overflow-x: hidden;
            padding-right: 20px;
          }
        }

        .right-sidebar {
          // flex: 0 0 318px;
          padding: 0 20px;
          border-left: 1px solid $body-border-color;
          // fixed
          position: fixed;
          top: 0;
          right: 0;
          max-width: 320px;
          width: 100%;
          height: 100%;

          .right-sidebar-inner-wrp {
            // position: sticky;
            // top: 103px;
            padding-top: 130px;
          }
        }
      }

      .middle-content {
        // flex: 1;
        padding: 0 20px;

        .top-form-wrp {
          margin-top: 20px;

          &.flex {
            display: flex;
            justify-content: space-between;
          }

          form {
            .form-row {
              margin-left: -10px;
              margin-right: -10px;

              .form-group {
                max-width: 300px;
                margin-bottom: 20px;
                padding: 0 10px;
                width: 100%;
                flex: auto;

                @include media-breakpoint-up(xl) {
                  width: auto;
                  flex: 1;
                }

                &.button-wrp {
                  display: flex;

                  .btn {
                    @include media-breakpoint-up(xl) {
                      margin-left: auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
