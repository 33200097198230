.card-footer {
  background: transparent;
  padding: 18px;
  display: flex;
  align-items: center;
  background-color: $card-footer-bg;
  border-top: 1px solid $card-border-color;
  margin-top: auto;

  .po-image-wrp {
    margin-right: 10px;
  }

  .po-name {
    font-weight: 700;
  }

  // client card specific
  .all-clients-wrp & {
    justify-content: space-between;
    border-top: 1px solid $card-border-color;

    .client-since {
      font-size: 12px;
      line-height: 18px;
      color: $grey-500;

      .year {
        color: $secondary;
        font-weight: 600;
      }
    }
  }

  // leaves card specific
  .my-leaves-wrp & {
    justify-content: space-between;

    .start-date,
    .end-date {
      font-size: 12px;
      line-height: 18px;
      font-weight: $font-semibold;

      span {
        color: $grey-500;
      }
    }
  }
}
