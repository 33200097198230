.card {
  box-shadow: 0px 2px 5px #0000000D;
  border: 1px solid $card-border-color;
  border-radius: 10px;
  overflow: hidden;
  min-width: 280px;
  max-width: 280px;
  width: 100%;
  text-decoration: none;
  color: $secondary;

  &:hover {
    color: $secondary;
  }

  .card-url {
    border-bottom: 1px solid $card-border-color;
  }

  > .card-stats:first-child {
    border: none;
  }

  // client card specific
  .all-clients-wrp & {
    min-width: 380px;
    max-width: 380px;
  }

  // leaves card specific
  .my-leaves-wrp & {
    width: 100%;
    max-width: 344px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}
