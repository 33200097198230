form {
  .form-row {
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
    flex-wrap: wrap;

    .col {
      padding: 0 15px;
    }
  }

  .form-group {
    margin-bottom: 10px;

    @include media-breakpoint-up(xxl) {
      margin-bottom: 25px;
    }
  }

  .form-control {
    height: 50px;
    border-color: $grey-300;
    font-size: 14px;
    color: $secondary;
    font-weight: $font-semibold;
    padding: 16px 20px;
    border-radius: 5px;

    &::placeholder {
      color: $grey-500;
      font-weight: $font-semibold;
    }

    &:focus {
      box-shadow: none;
      border-color: $secondary;
    }

    &:disabled {
      background-color: $grey-100;
      color: $grey-400;
    }
  }

  // checkbox styles
  .form-check {
    display: flex;
    align-items: center;
    line-height: normal;
    min-height: unset;
    padding-left: 0;
    margin-bottom: 15px;

    .form-check-input {
      display: grid;
      width: 19px;
      height: 19px;
      border: 2px solid $grey-300;
      float: none;
      margin: 0;
      position: relative;
      z-index: 1;
      background-color: $white;
      flex: 0 0 auto;

      &::after {
        content: "";
        position: absolute;
        top: -2px;
        left: -2px;
        z-index: 1;
        width: 20px;
        height: 20px;
        display: block;
        background-image: url($form-check-icon);
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        transform: scale(0);

        @include custom-transition(transform);
      }

      &:checked[type="checkbox"] {
        background-image: none;
        background-color: transparent;

        &::after {
          transform: scale(1);
        }
      }

      &:focus {
        box-shadow: none;
      }

      &:disabled {
        opacity: 1;
      }
    }

    &.disabled {
      .form-check-input {
        background-color: $grey-200;
      }

      .form-check-label {
        color: $grey-400;
        opacity: 1;
      }
    }
  }

  // radio btn styles
  .form-radio {
    display: flex;
    align-items: center;
    line-height: normal;
    min-height: unset;
    padding-left: 0;
    margin-bottom: 15px;

    .form-check-radio {
      display: grid;
      width: 20px;
      height: 20px;
      border: 2px solid $grey-300;
      float: none;
      margin: 0;
      position: relative;
      z-index: 1;
      appearance: none;
      border-radius: 50%;
      background-color: $white;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius: 50%;
        z-index: 1;
        width: 10px;
        height: 10px;
        display: block;
        transform: translate(-50%, -50%) scale(0);
        background-color: $secondary;

        @include custom-transition(transform);
      }

      &:checked[type="radio"] {
        background-image: none;
        background-color: transparent;
        border-color: $secondary;

        &::after {
          transform: translate(-50%, -50%) scale(1);
        }
      }

      &:focus {
        box-shadow: none;
      }
    }

    &.disabled {
      .form-check-radio {
        background-color: $grey-200;
      }

      .form-check-label {
        color: $grey-400;
      }
    }
  }

  // toggle btn styles
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
    margin: 0;

    input {
      opacity: 0;
      width: 0;
      height: 0;
      outline: none;

      &:checked + .slider {
        background-color: $toggle-active;

        &:before {
          transform: translateX(16px);
          background-color: $white;
        }
      }

      &:focus + .slider {
        box-shadow: 0 0 1px #2196f3;
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $grey-300;
      -webkit-transition: 0.4s;
      transition: 0.4s;

      &:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 4px;
        bottom: 4px;
        background-color: $grey-500;
        border-radius: 2px;
        transition: 0.4s;
        box-shadow: 0px 2px 5px #00000029;
      }
    }

    &.round {
      height: 25px;

      .slider {
        border-radius: 20px;

        &:before {
          height: 18px;
          width: 18px;
          border-radius: 50%;
        }
      }
    }

    &.disabled {
      opacity: 0.4;
    }
  }

  // select box styles
  .basic-single {
    box-shadow: 0px 2px 5px $grey-200;

    .select__control {
      height: 50px;
      border-color: $grey-300;
      box-shadow: none;

      svg {
        @include custom-transition(transform);
      }

      .select__value-container {
        padding: 0 20px;

        .select__single-value {
          color: $secondary;
          font-weight: $font-semibold;
        }
      }

      &--is-focused {
        border-color: $secondary;
      }

      &--menu-is-open {
        .select__dropdown-indicator {
          svg {
            transform: rotate(180deg);
          }
        }
      }

      .select__indicator-separator {
        margin-bottom: 12px;
        margin-top: 12px;
      }
    }

    .select__menu {
      z-index: 999;
      border: 1px solid $secondary;
    }

    &.select--is-disabled {
      .select__control {
        .select__value-container {
          .select__single-value {
            color: $grey-400;
          }
        }
      }
    }
  }

  // date range styles
  .react-daterange-picker {
    width: 100%;

    &__wrapper {
      height: 50px;
      border-radius: 5px;
      background: $white;
      border-color: $grey-300;
      width: 100%;
      padding: 0 30px 0 10px;
    }

    &__clear-button {
      display: none;
    }

    &__inputGroup {
      box-sizing: border-box;
      flex-grow: 0;
    }

    &__button {
      position: absolute;
      right: 20px;
      z-index: 1;
      padding: 0;
    }

    &__calendar-button {
      width: 20px;
      height: 20px;

      svg {
        display: none;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 20px;
        height: 20px;
        background-image: url($calendar);
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    &__calendar {
      z-index: 7;

      .react-calendar {
        border-radius: 5px;
        border-color: $grey-300;
      }
    }
  }

  // date picker styles
  .react-datepicker-wrapper {
    width: 100%;
  }

  // input-label-left-with bg variation styles
  .input-label-left-variation {
    position: relative;
    z-index: 1;
    border-radius: 10px 0px 0px 10px;
    overflow: hidden;

    .form-label {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      background-color: $grey-300;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 15px 20px;
      font-size: 14px;
      color: $secondary;
      min-width: 145px;
      margin: 0;
    }

    .form-control {
      padding-left: 165px;
      border-radius: 12px 5px 5px 12px;
    }
  }

  // max hours input number styles
  .max-hour-input-wrp {
    position: relative;
    z-index: 1;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 22px;
      height: 22px;
      right: 20px;
      background-image: url($clock-hour);
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 1;
    }

    input#max_hours_limit {
      padding-right: 60px;
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
}
