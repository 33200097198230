.daily-time-logs-table-wrp {
  width: calc(100vw - 340px);
  overflow-x: auto;

  .daily-time-logs-table {
    min-height: 220px;
    overflow: auto;
    border-radius: 10px;
    border: 1px solid $card-border-color;
    min-width: 1612px;

    .tasks-heading-wrp,
    .daily-tl-item {
      background: $white;
      display: flex;
      border-bottom: 1px solid $card-border-color;

      .project {
        flex: 1 0 10%;
        display: flex;
        padding: 13px 20px;
        border-right: 1px solid $card-border-color;
      }

      .task,
      .date,
      .lstatus,
      .non-billable,
      .spent,
      .actions {
        flex: 1 0 8%;
        padding: 13px 20px;
        border-right: 1px solid $card-border-color;
      }

      .notes {
        flex: 1 0 25%;
        padding: 13px 20px;
        border-right: 1px solid $card-border-color;
      }

      .actions {
        border: none;
      }
    }

    .tasks-heading-wrp {
      font-size: 16px;
      font-weight: 600;
      color: $grey-500;
    }

    .daily-tl-item-wrp {
      .daily-tl-item {
        &:last-child {
          border: none;
        }

        .actions {
          .action-icon-wrp {
            display: flex;
            gap: 20px;

            .action-item {
              width: 16px;

              a {
                display: block;

                img {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
